exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-cat-facts-js": () => import("./../../../src/pages/apps/cat-facts.js" /* webpackChunkName: "component---src-pages-apps-cat-facts-js" */),
  "component---src-pages-blog-contentful-blog-posts-url-js": () => import("./../../../src/pages/blog/{contentfulBlogPosts.url}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-posts-url-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-curriculum-index-js": () => import("./../../../src/pages/curriculum/index.js" /* webpackChunkName: "component---src-pages-curriculum-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

